import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getAllCountry } from './rapportApi';
import { useDispatch } from 'react-redux';
import { useCurrentUser } from 'features/Auth/authApi';
import { Country } from "./RapportType";

interface CountryFieldProps {
    selectPays: (string) => void;
}

export default function CountryField(props: CountryFieldProps) {
    const {selectPays} = props;
    const dispatch = useDispatch()
    const currentUser = useCurrentUser();
    const [countryList, setCountryList] = useState<Country[]>([]);

    function handleCountry(data: Country[]) {
        if (data) {
            setCountryList(data)
        }
    }

    function getCountry() {
        dispatch<any>(getAllCountry(currentUser!.token, handleCountry))
    }

    useEffect(() => {
        getCountry()
    }, []);


    return (
        <Autocomplete
            id="country-select"
            sx={{ width: 300 }}
            options={countryList}
            autoHighlight
            fullWidth
            onChange={(event, value) => selectPays(value?.libelle)}
            getOptionLabel={(option) => option.libelle}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://embarquee-img.lenship.fr/pays/${option.code.toLowerCase()}.png`}
                        src={`https://embarquee-img.lenship.fr/pays/${option.code.toLowerCase()}.png`}
                        alt=""
                    />
                    {option.libelle}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Pays"
                    fullWidth
                    style={{ backgroundColor: 'white' }}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    )
};