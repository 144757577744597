import React  from "react";

import { Bar } from 'react-chartjs-2';
import {
  Chart, ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js'
import { lastDays, repartitionCategory } from "../LogType";
import { Check, Close } from "@material-ui/icons";
Chart.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title);

interface BarLastDaysChartProps {
    lastDays: lastDays[];
}

const BarLastDaysChartChart = (props: BarLastDaysChartProps) => {
    const { lastDays } = props;

    const labels = lastDays.map((item) => { return item.day })
    const data = lastDays.map((item) => { return item.number })


    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: 'Suivi 7 derniers jours',
            },
        },
    };

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Rapports',
                data: data,
                backgroundColor: 'rgba(53, 162, 235, 0.7)',
            },
        ],
    };

    return (
        <Bar data={chartData} options={options} />
    );
};

export default BarLastDaysChartChart;