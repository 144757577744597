import { CssBaseline, Grid, Container, Paper, ThemeProvider, createTheme } from "@mui/material"
import Header from "page/Header"
import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { useCurrentUser, useLogged } from './Auth/authApi';
import LoginPage from "./Auth/LoginPage"
import RegisterPage from "./Auth/RegisterPage"
import UserModifyPage from "./User/UserAccountPage"
import AccueilPage from "./Accueil/AccueilPage";
import CreationRapportPage from "./Rapports/CreationRapportPage";
import UserManager from "./User/UserManager";
import LogPage from "./Logs/LogPage";


function App() {

    const logged = useLogged();
    const user = useCurrentUser();
    const theme = createTheme({
        components: {
          MuiCssBaseline: {
            styleOverrides: (themeParam) => ({
              body: {backgroundColor : "Snow"},
            }),
          },
        },
      });

    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Header />
                <Container sx={{minHeight: "100vh"}} maxWidth="lg">
                <Paper square={true} sx={{minHeight: "100vh", pt:1}}>
                    <Grid item xs>
                        <main>
                            <Routes>
                                {(logged && user?.role === "admin") && (
                                    <>
                                        <Route path="/manageuser" element={<UserManager />} />
                                        <Route path="/updateuser" element={<UserModifyPage />} />
                                        <Route path="/createuser" element={<RegisterPage />} />
                                        <Route path="/log" element={<LogPage />} />
                                    </>
                                )}
                                {logged && (
                                    <>
                                        <Route path="/" element={<AccueilPage />} />
                                        <Route path="/create" element={<CreationRapportPage />} />
                                        <Route path="/account" element={<UserModifyPage />} />
                                        <Route path="/*" element={<Navigate to="/" />} />
                                    </>
                                )}
                                {!logged && (
                                    <>
                                        <Route path="/login" element={<LoginPage />} />
                                        <Route path="/*" element={<Navigate to="/login" />} />
                                    </>
                                )}
                            </Routes>
                        </main>
                    </Grid>
                </Paper>
                </Container>
            </ThemeProvider>

        </div>
    )
}

export default App