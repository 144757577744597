import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Typography, Grid } from "@mui/material";
import { Report } from "features/Rapports/RapportType";
import { MapsChart } from "page/MapsChart";
import React from "react";

interface ModalReportProps {
  report: Report;
  open: boolean;
  onClose: () => void;
}

const ModalReport = (props: ModalReportProps) => {
  const { report, open, onClose } = props;
  const backgroundColor = report.state === 'En attente' ? '#ddb8b8' : report.state === 'Synchronisé' ? '#9af7cb' : '#EACD99';
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6" color="black" align="center" sx={{ borderBottom: 1, borderColor: '#0000e0' }}>
          {report.ship.name} - {new Date(report.creationDate).toLocaleDateString('fr-FR')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <Grid item xs={12}>
              <img
                src={report.imageUrl}
                alt={`Rapport #${report.id}`}
                style={{
                  width: '80%',
                  maxHeight: '200px',
                  objectFit: 'contain',
                  margin: '0 auto',
                  display: 'block',
                }}
              />
            </Grid>
            {/* TITRE INFORMATION */}
            <Grid item xs={12} py={2}>
              <Typography variant="h5" color="black" align="left" sx={{ borderBottom: 1, borderColor: '#666666' }}>
                Informations :
              </Typography>
            </Grid>
            {/* TYPE ET CAT */}
            <Grid item xs={4}>
              <Typography variant="h6" color="black" >Type & Catégorie:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography align="left">{report.ship.type + " , " + report.ship.category}</Typography>
            </Grid>
            {/* DATE DE CREATION */}
            <Grid item xs={4}>
              <Typography variant="h6" color="black" >Date de création:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography align="left">{new Date(report.creationDate).toLocaleDateString('fr-FR')}</Typography>
            </Grid>
            {/* DESCRIPTION */}
            <Grid item xs={4}>
              <Typography variant="h6" color="black" >Description:</Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography align="left">{report.description}</Typography>
            </Grid>
            {/* TITRE ETAT */}
            <Grid item xs={12} py={2}>
              <Typography variant="h5" color="black" align="left" sx={{ borderBottom: 1, borderColor: '#666666' }}>
                États :
              </Typography>
            </Grid>
            {/* ETAT */}
            <Grid item xs={3} sx={{ backgroundColor: backgroundColor, borderRadius: 5}}>
              <Typography align="center">{report.state}</Typography>
            </Grid>
            <Grid item xs={9}>
            </Grid>
            {/* TITRE POSITION */}
            <Grid item xs={12} py={2}>
              <Typography variant="h5" color="black" align="left" sx={{ borderBottom: 1, borderColor: '#666666' }}>
                Position :
              </Typography>
            </Grid>
            {/* LAT ET LONG */}
            <Grid item xs={6}>
              <Typography variant="h6" color="black" >Latitude:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="left">{report.gps.split(',')[0]}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" color="black" >Longitude:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="left">{report.gps.split(',')[1]}</Typography>
            </Grid>
            {/* CARTE */}
            <Grid item xs={12} py={2}>
              <MapsChart latitude={parseInt(report.gps.split(',')[0])} longitude={parseInt(report.gps.split(',')[1])} height={300} />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button onClick={onClose}>Fermer</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalReport;