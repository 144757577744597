import React, { useState } from 'react';
import { Button } from '@mui/material';
import ModalReport from './ModalReport';

const DetailsButton = ({ report }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleButtonClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Button onClick={handleButtonClick}>Détails</Button>
      <ModalReport report={report} open={openModal} onClose={handleCloseModal} />
    </>
  );
};

export default DetailsButton;