import { Container, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useCurrentUser } from "features/Auth/authApi";
import { useDispatch } from "react-redux";
import { deleteReport, getReports } from "features/Rapports/rapportApi";
import ReportTable from "./ReportTable";
import { Report } from "features/Rapports/RapportType";
import { toast } from "react-toastify";
import Popup from "page/Dialog";


const AccueilPage = () => {
  const currentUser = useCurrentUser();
  const dispatch = useDispatch();
  const [reports, setReports] = useState<Report[]>([]);
  const [displayPopup, setDisplayPopup] = useState<boolean>(false);
  const [idReportDelete, setIdReportDelete] = useState<number | null>(null);


  function init(){
    dispatch<any>(getReports(currentUser!.token, (data) => { setReports(data) }))
  }


  useEffect(() => {
    init()
  }, []);

  function confirmDelete(reportId: number | null){
    if(reportId){
      function successDelete(){
        toast.success('Rapport supprimé avec succès');
        init()
      }
      dispatch<any>(deleteReport(reportId, currentUser!.token,successDelete))
    }
    
  }

  const handleDelete = (reportId: number) => {
    setDisplayPopup(true)
    setIdReportDelete(reportId)
  }

  function cancelDelete(){
    setIdReportDelete(null)
    setDisplayPopup(false)
  }


  return (
    <Container>
      <Grid item mb={2} xs={12}>
        <Typography variant="h4" letterSpacing={5} align="left" my={2} gutterBottom >
          Rapports
        </Typography>
        <Divider sx={{ backgroundColor: 'blue' }} />
      </Grid>
      <Grid item mt={5} xs={12}>
        <ReportTable reports={reports} onDelete={handleDelete}/>
      </Grid>
      {displayPopup && (
        <Popup title={"Attention"} message={"Voulez vous suppprimez ce rapport ?"} onConfirm={() => confirmDelete(idReportDelete)} onClose={cancelDelete}/>
      )}
      
    </Container>
  );
}

export default AccueilPage;
