import React, { useState } from 'react';
import { TextField, Grid } from '@mui/material';

const GPSTextField = ({ label, value, onChange }) => {
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');

  const handleLatitudeChange = (e: any) => {
    const inputValue = e.target.value;
    // Vérifier si la valeur est valide pour la latitude (entre -90 et 90)
    if (inputValue === '' || inputValue === '-'  || (parseFloat(inputValue) >= -90 && parseFloat(inputValue) <= 90)) {
      setLatitude(inputValue);
      onChange(`${inputValue},${longitude}`);
    }
  };

  const handleLongitudeChange = (e: any) => {
    const inputValue = e.target.value;
    // Vérifier si la valeur est valide pour la longitude (entre -180 et 180)
    if (inputValue === '' || inputValue === '-'  || (parseFloat(inputValue) >= -180 && parseFloat(inputValue) <= 180)) {
      setLongitude(inputValue);
      onChange(`${latitude},${inputValue}`);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          id="latitude"
          label={`${label} Latitude`}
          fullWidth
          value={latitude}
          onChange={handleLatitudeChange}
          placeholder="-90 à 90"
          style={{ backgroundColor: 'white' }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="longitude"
          label={`${label} Longitude`}
          fullWidth
          value={longitude}
          onChange={handleLongitudeChange}
          placeholder="-180 à 180"
          style={{ backgroundColor: 'white' }}
        />
      </Grid>
    </Grid>
  );
};

export default GPSTextField;