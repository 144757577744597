import { Button, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { CurrentUser, User } from "./UserType";
import React, { useEffect, useState } from "react";
import { useCurrentUser } from "features/Auth/authApi";
import { Close, Edit } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { deleteUser, updateUser } from "./UserApi";
import { updateAuthUser } from "features/Auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";


export interface ModifyUserPageProps {
  user?: User;
}

const ModifyUserPage = (props: ModifyUserPageProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const location = useLocation();
  const user = location.state?.user
  const [userForm, setUserForm] = useState<User>();
  const [statePage, setStatePage] = useState<number>(1);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [mail, setMail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [editFirstName, seteditFirstName] = useState<boolean>(false);
  const [editLastName, setEditLastName] = useState<boolean>(false);
  const [editMail, setEditMail] = useState<boolean>(false);
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);


  function fcteditFirstName() {
    const editMode = !editFirstName;
    seteditFirstName(editMode)
    if (!editMode) {
      setFirstName("")
    }else if(userForm?.firstname !== undefined){
      setFirstName(userForm.firstname)
    }
  }

  function fcteditLastName() {
    const editMode = !editLastName;
    setEditLastName(editMode)
    if (!editMode) {
      setLastName("")
    }else if(userForm?.lastname !== undefined){
      setLastName(userForm.lastname)
    }
  }

  function fcteditMail() {
    const editMode = !editMail;
    setEditMail(editMode)
    if (!editMode) {
      setMail("")
    }else if(userForm?.email !== undefined){
      setMail(userForm.email)
    }
  }

  function fctEditPassword() {
    const editMode = !editPassword;
    setEditPassword(editMode)
    if (!editMode) {
      setPassword("")
    }
  }


  useEffect(() => {
    if (user !== null && user !== undefined) {
      setUserForm(user)
    } else if (currentUser !== null && currentUser !== undefined) {
      setUserForm(currentUser)
    }
  }, [currentUser, user]);


  function fctSuccess(data: CurrentUser) {
    if (data) {
      if (user === null || user === undefined) {
        dispatch(updateAuthUser(data));
      } else {
        setUserForm(data)
      }
    }
    fctReset();
  }

  function fctSave() {
    const formData = {}
    if (firstName !== "") {
      formData["firstname"] = firstName
    }
    if (lastName !== "") {
      formData["lastname"] = lastName
    }
    if (password !== "") {
      formData["password"] = password
    }
    dispatch<any>(updateUser(formData, userForm!.id, currentUser!.token, fctSuccess))
  }

  function fctModify() {
    setStatePage(2)
  }

  function fctDemandeDelete() {
    setOpenDialog(true)
  }

  function fctDelete() {
    setOpenDialog(false)
    dispatch<any>(deleteUser(userForm!.id, currentUser!.token, currentUser, () => { navigate("/manageuser") }))
  }

  function fctReset() {
    setFirstName("")
    setLastName("")
    setPassword("")
    setStatePage(1)
    seteditFirstName(false)
    setEditLastName(false)
    setEditPassword(false)
  }

  return (
    <>
      <Container>
        <Grid item mb={2} xs={12}>
          <Typography variant="h4" letterSpacing={5} align="left" my={2} gutterBottom >
            Gestion des Utilisateurs
          </Typography>
          <Divider sx={{ backgroundColor: 'blue' }} />
        </Grid>
      </Container>

      <Container maxWidth="sm">
        {statePage === 1 && (
          <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" align="center" gutterBottom>
              Informations Utilisateur
            </Typography>
    
            {/* EMAIL */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2" align="left" gutterBottom>
                  Email :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" align="left" gutterBottom>
                  {userForm?.email}
                </Typography>
              </Grid>
            </Grid>
    
            {/* PRENOM */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2" align="left" gutterBottom>
                  Prénom :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" align="left" gutterBottom>
                  {userForm?.firstname}
                </Typography>
              </Grid>
            </Grid>
    
            {/* NOM */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2" align="left" gutterBottom>
                  Nom :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" align="left" gutterBottom>
                  {userForm?.lastname}
                </Typography>
              </Grid>
            </Grid>
    
            {/* ROLE */}
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="body2" align="left" gutterBottom>
                  Rôle :
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" align="left" gutterBottom>
                  {userForm?.role}
                </Typography>
              </Grid>
            </Grid>
    
            <Divider sx={{ my: 2 }} />
    
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={12} md={6}>
                <Button onClick={fctModify} variant="contained" color="primary" fullWidth sx={{ mt: 0.5 }}>
                  Mettre à jour
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <Button
                  onClick={() => fctDemandeDelete()}
                  disabled={currentUser!.role !== "admin"}
                  variant="contained"
                  color="error"
                  fullWidth
                  sx={{ mt: 0.5 }}
                >
                  Supprimer
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        )}
        {statePage === 2 && (
          <>
            <Typography variant="h6" align="center" gutterBottom>
              Mettre à jour les informations
            </Typography>
            <Grid container spacing={2}>
              {/* PRENOM */}
              <Grid item xs={12}>
                <Typography variant="body1" align="left" gutterBottom>
                  Prénom :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="firstname"
                  placeholder={userForm?.firstname}
                  value={firstName}
                  disabled={!editFirstName}
                  onChange={e => setFirstName(e.target.value)}
                  helperText={firstName !== "" ? `Le prénom va être mis à jour : ${firstName}` : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => fcteditFirstName()}
                        >
                          {editFirstName ? <Close color="error" /> : <Edit color="primary" />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* NOM */}
              <Grid item xs={12}>
                <Typography variant="body1" align="left" gutterBottom>
                  Nom :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder={userForm?.lastname}
                  name="lastname"
                  type="text"
                  value={lastName}
                  disabled={!editLastName}
                  onChange={e => setLastName(e.target.value)}
                  helperText={lastName !== "" ? `Le nom va être mis à jour : ${lastName}` : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => fcteditLastName()}
                        >
                          {editLastName ? <Close color="error" /> : <Edit color="primary" />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
                  {/* EMAIL */}
              <Grid item xs={12}>
                <Typography variant="body1" align="left" gutterBottom>
                  Adresse mail :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  placeholder={userForm?.email}
                  name="email"
                  type="text"
                  value={mail}
                  disabled={!editMail}
                  onChange={e => setMail(e.target.value)}
                  helperText={mail !== "" ? `Le nom va être mis à jour : ${mail}` : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => fcteditMail()}
                        >
                          {editMail ? <Close color="error" /> : <Edit color="primary" />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {/* MOT DE PASSE */}
              <Grid item xs={12}>
                <Typography variant="body1" align="left" gutterBottom>
                  Mot de passe :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Mot de passe"
                  name="password"
                  type="password"
                  value={password}
                  disabled={!editPassword}
                  onChange={e => setPassword(e.target.value)}
                  helperText={password !== "" ? "Le mot de passe va être mis à jour" : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => fctEditPassword()}
                        >
                          {editPassword ? <Close color="error" /> : <Edit color="primary" />}

                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" align="left" gutterBottom>
                Confirmez le mot de passe :
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Confirmez le mot de passe"
                  name="confirmPassword"
                  type="password"
                  disabled={password === ""}
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.target.value)}
                  error={password !== confirmPassword}
                  helperText={password !== confirmPassword ? "Les mots de passe ne corresponde pas" : ""}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Button onClick={fctReset} variant="contained" color="error" fullWidth sx={{ mt: 0.5 }} >
                  annuler
                </Button>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Button onClick={fctSave} variant="contained" disabled={(firstName === "" && lastName === "" && password === "") || (password !== confirmPassword)} color="success" fullWidth sx={{ mt: 0.5 }} >
                  enregistrer
                </Button>
              </Grid>

            </Grid>
          </>
        )}
        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Voulez vous vraiment supprimer le compte ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Non</Button>
            <Button onClick={fctDelete} autoFocus>
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>

  );
}

export default ModifyUserPage;
