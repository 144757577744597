import React  from "react";

import { Doughnut } from 'react-chartjs-2';
import {
  Chart, ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js'
import { repartitionCategory } from "../LogType";
import { Check, Close } from "@material-ui/icons";
Chart.register(ArcElement, Tooltip, Legend, CategoryScale,
  LinearScale,
  BarElement,
  Title);

interface DonutRepartitionChartProps {
    repartition: repartitionCategory[];
}

const DonutRepartitionChart = (props: DonutRepartitionChartProps) => {
    const { repartition } = props;

    const labels = repartition.map((item) => { return item.category })
    const data = repartition.map((item) => { return item.reportCount })

    function getRandomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function generateRandomRGBA(): string {
        const r = getRandomInt(0, 255);
        const g = getRandomInt(0, 255);
        const b = getRandomInt(0, 255);
        const a = 1;
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    }

    const colorMap = new Map();

    colorMap.set('Pétrolier', "rgba(43, 33, 24, 1)"); 
    colorMap.set('Porte container', "rgba(65, 70, 61, 1)"); 
    colorMap.set('Frégate', "rgba(252, 202, 70, 1)"); 
    colorMap.set('Porte avion', "rgba(254, 127, 45, 1)"); 
    colorMap.set('Sous marin', "rgba(43, 19, 8, 1)"); 
    colorMap.set('Croisière', "rgba(10, 36, 99, 1)"); 
    colorMap.set('Chalutier', "rgba(68, 175, 105, 1)"); 
    colorMap.set('Voilier', "rgba(36, 123, 160, 1)"); 
    colorMap.set('Yacht', "rgba(237, 255, 171, 1)"); 

    const backgroundColor: string[] = repartition.map((item) => colorMap.get(item.category));

    const chartData = {
        labels: labels,
        datasets: [{
            label: 'Nombre',
            data: data,
            borderWidth: 1,
            backgroundColor: backgroundColor
        }]
    };

    return (
        <Doughnut data={chartData} options={{ cutout: "70%" }} />
    );
};

export default DonutRepartitionChart;