import IconButton from '@mui/material/IconButton';
import { AccountCircle, ExitToApp } from '@material-ui/icons';
import { AppBar, Box, Button, Toolbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useCurrentUser, logout, getInfoShip } from 'features/Auth/authApi';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';


interface ShipInformation {
  nameShip: string;
  immatriculation: string;
}

const Header: React.FC = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [shipInformation, setShipInformation] = useState<ShipInformation>();

  function init(data: ShipInformation){
    if(data){
      console.log(data)
      setShipInformation(data)
    }
  }

  useEffect(() => {
    dispatch<any>(getInfoShip(init))
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Box textAlign="left" style={{ margin: 5, marginRight: 20 }}>
            <Typography variant='h4' style={{fontSize: '150%'}}>Lens-ship</Typography>
            {shipInformation && (
              <Typography variant='h5' style={{fontSize: '100%'}} fontWeight={"bold"}>{`${shipInformation?.nameShip} ${shipInformation?.immatriculation}`}</Typography>
            )}
          </Box>
          
          {user && (
            <>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              onClick={e => navigate("/")}
              sx={{ mx: 1, color: 'white', display: 'block' }}
            >
              {"Accueil"}
            </Button>
            <Button
              onClick={e => navigate("/create")}
              sx={{ mx: 1, color: 'white', display: 'block' }}
            >
              {"Création de rapport"}
            </Button>
            { (user?.role === "admin") &&
              <Button
                onClick={e => navigate("/log")}
                sx={{ mx: 1, color: 'white', display: 'block' }}
              >
                {"Suivi des rapports"}
            </Button>
            }
            { (user?.role === "admin") &&
              <Button
                onClick={e => navigate("/manageuser")}
                sx={{ mx: 1, color: 'white', display: 'block' }}
              >
                {"Gestion utilisateur"}
            </Button>
            }
          </Box>
          </>
          )}
          {user && (
            <>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={e => navigate("/account")}
              >
                <AccountCircle />
              </IconButton>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                color="inherit"
                onClick={e => dispatch<any>(logout())}
              >
                <ExitToApp />
              </IconButton>
            </>
          )}

        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;