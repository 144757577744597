import { Button, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useCurrentUser } from "features/Auth/authApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { analyseImage, createReport, getAllShipCategories, getAllShipTypes, saveImage } from "./rapportApi";
import { ShipCategory, ShipType, resultAnalyse } from "./RapportType";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DonutChart from "page/DonutChart";
import GPSTextField from "./GPSTextField";
import CountryField from "./CountryField";


const CreationRapportPage = () => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [image, setImage] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  //analyse
  const [timeAnalyse, setTimeAnalyse] = useState<string | null>(null);
  const [confianceAnalyse, setConfianceAnalyse] = useState<number | null>(null);
  //Champs rapport
  const [type, setType] = useState<ShipType>();
  const [nom, setNom] = useState<string>('');
  const [categorie, setCategorie] = useState<ShipCategory>();
  const [immatriculation, setImmatriculation] = useState<string>('');
  const [pays, setPays] = useState<string>('');
  const [GPS, setGPS] = useState<string>('');
  const [description, setDescription] = useState<string>('');

  const [listShipType, setListShipType] = useState<ShipType[]>([]);
  const [listShipCategories, setListShipCategories] = useState<ShipCategory[]>([]);
  const [listShipCategoriesFiltered, setListShipCategoriesFiltered] = useState<ShipCategory[]>([]);


  function changeShipCategorie(categorie: string) {
    const shipCategory = listShipCategories.find(item => item.categorie === categorie)
    setCategorie(shipCategory);
  }
  
  const handleShipCategorieChange = (e) => {
    const value = e.target.value
    changeShipCategorie(value)
  };

  function changeShipType(type: string) {
    const shipType = listShipType.find(item => item.type === type)
    setType(shipType);
  }

  const handleShipTypeChange = (e) => {
    const value = e.target.value
    changeShipType(value)
    resetCategories()
  };

  function gpsValid() {
    if (GPS.trim() !== '') {
      const splitGps = GPS.split(',')
      if (splitGps.length === 2) {
        if (splitGps[0].trim() !== '' && splitGps[1].trim() !== '') {
          return true
        }
      }
    }
    return false
  }

  const formulaireValid = (
    type !== undefined &&
    nom.trim() !== '' &&
    categorie !== undefined &&
    immatriculation.trim() !== '' &&
    pays.trim() !== '' &&
    gpsValid() &&
    description.trim() !== ''
  );


  const handleImageUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const fileType = file?.type;

    if (file) {
      if (fileType !== "image/jpeg" && fileType !== "image/jpg" && fileType !== "image/png") {
        toast.warning("Seuls les fichiers JPG et PNG sont autorisés.")
        event.target.value = "";
        return;
      } else {
        setImageFile(file)
        const reader = new FileReader();

        reader.onload = () => {
          setImage(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleGPSChange = (value) => {
    setGPS(value);
  };

  function imageResult(data: resultAnalyse) {
    if (data) {
      if (data.type && data.categorie) {
        changeShipType(data.type)
        changeShipCategorie(data.categorie)
        filterCategories(data.type)
      } else {
        toast.warning("L'Analyse n'a pu donner un résultat satisfaisant")
      }
      const timeAnalyse = data.temps_analyse.toFixed(2).toString();
      setTimeAnalyse(timeAnalyse)
      const confiance = parseFloat(data.confiance.toFixed(2));
      setConfianceAnalyse(confiance)
    }
  }

  function sendImageAnalyse() {
    if (imageFile) {
      dispatch<any>(analyseImage(imageFile, currentUser!.token, imageResult))
    }
  }

  function saveReport() {
    sendImageSave()
  }

  function sendImageSave() {
    if (imageFile) {
      dispatch<any>(saveImage(imageFile, currentUser!.token, (data) => { sendNewReport(data.image_id) }))
    }
  }

  function sucessRapport() {
    toast.success('Rapport enregistré avec succès');
    navigate("/")
  }

  function sendNewReport(idImage: number) {
    const data = {
      type: type?.libelle,
      name: nom,
      category: categorie?.libelle,
      immatriculation: immatriculation,
      country: pays,
      gps: GPS,
      description: description,
      idImage: idImage
    };

    dispatch<any>(createReport(data, currentUser!.token, (data) => { sucessRapport() }))
  }

  function initShipType(data: ShipType[]) {
    if (data) {
      setListShipType(data)
    }
  }

  function filterCategories(type: string){
    const filteredList = listShipCategories.filter((item) => {return item.type === type})
    setListShipCategoriesFiltered(filteredList)
  }

  function resetCategoriesList(){
    if (type !== undefined) {
      filterCategories(type.type)
    }else{
      setListShipCategoriesFiltered([])
    }
  }

  function resetCategories(){
    setCategorie(undefined)
  }

  useEffect(() => {
    resetCategoriesList()
  }, [type]);

  useEffect(() => {
    dispatch<any>(getAllShipTypes(currentUser!.token, initShipType))
    dispatch<any>(getAllShipCategories(currentUser!.token, (data) => { setListShipCategories(data) }))
  }, []);

  return (
    <Grid container p={2}>
      {/* TITLE */}
      <Grid item mb={2} xs={12}>
        <Typography variant="h4" letterSpacing={5} align="left" my={2} gutterBottom >
          Création de rapport
        </Typography>
        <Divider sx={{ backgroundColor: 'blue' }} />
      </Grid>
      {/* BLOC IMAGE */}
      <Grid container alignContent="center" alignItems="center" p={1} sx={{ backgroundColor: "#f9f9f9", height: "48vh" }}>
        <Grid item xs={6} style={{ height: '100%' }}>
          {/* AFFICHAGE IMAGE */}
          <Grid container style={{ height: '100%' }} alignContent="center" alignItems="center" >
            {!image &&
              <Grid item style={{ textAlign: "center", width: "100%" }}>
                <Typography variant="h4" color="#00a6a6" align="center" my={2} gutterBottom>
                  Ajouter une image
                </Typography>
              </Grid>
            }
            {image &&
              <Grid item p={2}>
                <img src={image} id="image" alt="Uploaded" style={{ width: '100%', height: '100%', maxHeight: "40vh", objectFit: 'cover' }} />
              </Grid>
            }
          </Grid>
        </Grid>
        {/* BOUTON ET RESULTAT */}
        <Grid item pb={1} style={{ height: '100%' }} xs={6}>
          <Grid container style={{ height: '85%' }} alignContent="flex-start" alignItems="center" spacing={1} >
            {timeAnalyse && confianceAnalyse && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="body1">{"Analysé par l'IA en " + timeAnalyse + " secondes. Précision de " + confianceAnalyse + "%"}</Typography>
              </Grid>
            )}
            {confianceAnalyse && (
              <Grid item xs={12} style={{ height: '95%', textAlign: "center", paddingTop: 15 }}>
                <DonutChart percentage={confianceAnalyse} seuil={80} />
              </Grid>
            )}

          </Grid>
          <Grid container style={{ height: '15%' }} alignContent="flex-end" alignItems="center" >
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <input
                type="file"
                accept="image/jpeg, image/png, image/jpg"
                id="file-input"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />

              <label htmlFor="file-input">
                <Button variant="contained" color="warning" component="span">
                  Charger une image
                </Button>
              </label>

            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <Button id="analyseimagebtn" variant="contained" onClick={sendImageAnalyse} disabled={!image} color="primary">
                analyser l'image
              </Button>
            </Grid>
          </Grid>
        </Grid>

      </Grid>
      <Grid container p={1} mt={5} sx={{ backgroundColor: "#f9f9f9" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel shrink={type !== undefined} id="type-id">Type</InputLabel>
              <Select
                labelId="type-id"
                id="type"
                value={type?.type ?? null}
                label="Type"
                placeholder="Type"
                fullWidth
                style={{ backgroundColor: 'white' }}
                onChange={handleShipTypeChange}
                notched={type !== undefined}
              >
                {listShipType.map((item) => (
                  <MenuItem key={item.type} value={item.type}>{item.libelle}</MenuItem>
                ))}
              </Select>
            </ FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField id="nom" label="Nom" fullWidth value={nom} onChange={(e) => setNom(e.target.value)} style={{ backgroundColor: 'white' }} />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel shrink={categorie !== undefined} id="categorie-id">Catégorie</InputLabel>
              <Select
                id="categorie"
                labelId="categorie-id"
                value={categorie?.categorie ?? null}
                label="Catégorie"
                placeholder="Catégorie"
                fullWidth
                style={{ backgroundColor: 'white' }}
                disabled={type == undefined}
                onChange={handleShipCategorieChange}
                notched={categorie !== undefined}
              >
                {listShipCategoriesFiltered.map((item) => (
                  <MenuItem key={item.categorie} value={item.categorie}>{item.libelle}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField id="immatriculation" label="Immatriculation" fullWidth value={immatriculation} onChange={(e) => setImmatriculation(e.target.value)} style={{ backgroundColor: 'white' }} />
          </Grid>
          <Grid item xs={6}>
            {/*<TextField id="pays" label="Pays" fullWidth value={pays} onChange={(e) => setPays(e.target.value)} style={{ backgroundColor: 'white' }} />*/}
            <CountryField selectPays={setPays} />
          </Grid>
          <Grid item xs={6}>
            <GPSTextField label="GPS" value={GPS} onChange={handleGPSChange} />
          </Grid>
          <Grid item xs={12}>
            <TextField id="desciption" label="Description" fullWidth multiline rows={4} value={description} onChange={(e) => setDescription(e.target.value)} style={{ backgroundColor: 'white' }} />
          </Grid>
        </Grid>
        <Grid container mt={1} alignContent="center" alignItems="center" >
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button id="savereportbtn" variant="contained" onClick={saveReport} disabled={!formulaireValid} color="primary">
              Générer un rapport
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CreationRapportPage;

