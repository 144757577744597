import React, { useEffect, useState } from "react";
import UserTable from "./UserTable";
import { User } from "./UserType";
import { Button, Container, Divider, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteUser, getUsers } from "./UserApi";
import { useCurrentUser } from "features/Auth/authApi";
import { useNavigate } from "react-router-dom";
import Popup from "page/Dialog";
import { toast } from "react-toastify";

const UserManager = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const [users, setUsers] = useState<User[]>([]);
    const [displayPopup, setDisplayPopup] = useState<boolean>(false);
    const [idUserDelete, setIdUserDelete] = useState<number | null>(null);

    function init(){
        dispatch<any>(getUsers((data) => { setUsers(data) },currentUser!.token ))
    }

    useEffect(() => {
        init()
    }, []);

    const handleEdit = (user: User) => {
        navigate("/updateuser", {state : {user: user}})
    };


    function confirmDelete(reportId: number | null){
        if(reportId){
          function successDelete(){
            toast.success('Utilisateur supprimé avec succès');
            init()
          }
          dispatch<any>(deleteUser(reportId, currentUser!.token, currentUser,init))
        }
        
      }

    const handleDelete = (userId: number) => {
        setDisplayPopup(true)
        setIdUserDelete(userId)
    }

    function cancelDelete(){
        setIdUserDelete(null)
        setDisplayPopup(false)
    }

    return (

        <Container>
            <Grid item mb={2} xs={12}>
                <Typography variant="h4" letterSpacing={5} align="left" my={2} gutterBottom >
                    Gestion des Utilisateurs
                </Typography>
                <Divider sx={{ backgroundColor: 'blue' }} />
            </Grid>
            <Grid item mt={3} xs={12} style={{textAlign: 'right'}}>
                <Button
                    onClick={e => navigate("/createuser")}
                    variant="contained"
                >
                    {"Ajouter un utilisateur"}
                </Button>
            </Grid>
            <Grid item mt={5} xs={12}>
                <UserTable users={users} onEdit={handleEdit} onDelete={handleDelete} />
            </Grid>
            {displayPopup && (
                <Popup title={"Attention"} message={"Voulez vous suppprimez cet utilisateur ?"} onConfirm={() => confirmDelete(idUserDelete)} onClose={cancelDelete}/>
            )}
        </Container>
    );
};

export default UserManager;
