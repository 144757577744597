import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import { Report } from 'features/Rapports/RapportType';
import DetailsButton from './DetailsButton';
import { Delete } from '@material-ui/icons';
import { IconButton } from '@mui/material';

declare module '@mui/material/styles' {
  interface Components {
    [key: string]: any
  }
}


interface ReportTableProps {
    reports: Report[];
    onDelete: (reportId: number) => void;
}

const headerColor = '#e3e8e4'

const ReportTable = (props: ReportTableProps) => {
    const { reports,onDelete } = props;
   

    const columns = [
        {
            name: 'Type',
            label: 'Type',
            options: {
                setCellProps: () => ({ style: { fontWeight: 'bold'} }),
                setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),
  
            },
        },
        {
            name: 'Catégorie',
            label: 'Catégorie',
            options: {
              setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),

          },
        },
        {
            name: 'Nom',
            label: 'Nom',
            options: {
              setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),

          },
        },
        {
            name: 'Date',
            label: 'Date',
            options: {
                customBodyRender: (value) => {
                    return <span style={{ fontWeight: 'normal' }}>{new Date(value).toLocaleDateString('fr-FR')}</span>;
                },
                setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),
            },
        },
        {
            name: 'État',
            label: 'État',
            options: {
                customBodyRender: (value) => {
                    const backgroundColor = value === 'En attente' ? '#ddb8b8' : value === 'Synchronisé' ? '#9af7cb' : '#EACD99';
                    return (
                        <span style={{ backgroundColor, padding: '5px', borderRadius: '5px' }}>{value}</span>
                    );
                },
                setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),
            },
        },
        {
            name: 'Détails',
            label: 'Détails',
            options: {
              customBodyRender: (value) => {
                const reportData = reports.find((el) => el.id === value);
                return <DetailsButton report={reportData} />;
              },
              setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),
            },
          },
          {
            name: '',
            label: '',
            options: {
                customBodyRender: (value, tableMeta) => {
                    const reportState = reports[tableMeta.rowIndex].state;
                    if (reportState === 'En attente') {
                        return <IconButton color="error" onClick={() => onDelete(reports[tableMeta.rowIndex].id)}>
                                <Delete />
                              </IconButton>
                    } else {
                        return null;
                    }
                },
                setCellHeaderProps: () => ({ style: { backgroundColor: headerColor } }),
            },
        },
    ];




    const data = reports.map((report) => {
    return [
        report.ship.type,
        report.ship.category,
        report.ship.name,
        report.creationDate,
        report.state,
        report.id,
    ]});

    const options = {
        filter: true,
        responsive: "standard",
        selectableRows: "none",
        print: false,
        download: true,
        textLabels: {
            body: {
              noMatch: "Aucun enregistrement correspondant n'a été trouvé",
              toolTip: "Trier",
            },
            pagination: {
              next: "Suivant",
              previous: "Précédent",
              rowsPerPage: "Lignes par page :",
              displayRows: "sur",
            },
            toolbar: {
              search: "Rechercher",
              downloadCsv: "Télécharger CSV",
              print: "Imprimer",
              viewColumns: "Voir les colonnes",
              filterTable: "Filtrer le tableau",
            },
            filter: {
              all: "Tous",
              title: "FILTRES",
              reset: "RÉINITIALISER",
            },
            viewColumns: {
              title: "Voir les colonnes",
              titleAria: "Voir/Masquer les colonnes du tableau",
            },
            selectedRows: {
              text: "ligne(s) sélectionnée(s)",
              delete: "Supprimer",
              deleteAria: "Supprimer les lignes sélectionnées",
            },
          },
        rowsPerPage: 15,
    };

    return (
      <MUIDataTable
            title={"Liste des Rapports"}
            data={data}
            columns={columns}
            options={options}
        />
        
    );
}

export default ReportTable;