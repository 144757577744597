import React from "react"
import { Map, Marker } from "pigeon-maps"

interface MapsChartProps {
    latitude: number;
    longitude: number;
    height: number;
  }

export function MapsChart(props: MapsChartProps) {
    const {latitude, longitude, height} = props;
  return (
    <Map height={height} defaultCenter={[latitude, longitude]} defaultZoom={7}>
      <Marker width={50} anchor={[latitude, longitude]} />
    </Map>
  )
}