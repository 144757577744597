import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
import { Grid } from '@mui/material';
Chart.register(ArcElement);

interface DonutChartProps {
  percentage: number;
  seuil: number;
}

const DonutChart = (props : DonutChartProps) => {
  const {percentage, seuil} = props;
  const remainingPercentage = 100 - percentage;

  let backgroundColor: string[];
  if (percentage <= seuil) {
    backgroundColor = ['rgba(226,35,30, 1)','rgba(237,123,120, 1)']
  } else {
    backgroundColor = ['rgba(13, 102, 144, 1)','rgba(134, 178, 199, 1)']
  }

  const chartData = {
    datasets: [
      {
        data: [percentage, remainingPercentage],
        backgroundColor: backgroundColor,
        borderWidth: 1,
      },
    ],
  };

  return (
        <Doughnut data={chartData} options={{ maintainAspectRatio: false, responsive: true, cutout: "70%"}} />
  );
};

export default DonutChart;