import { Alert, Button, Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useCurrentUser } from "features/Auth/authApi";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import 'react-toastify/dist/ReactToastify.css';
import { getAnormalReport, getRepartitionReport, getReportsByDay, getReportsNoSync } from "./LogApi";
import { lastDays, repartitionCategory } from "./LogType";
import { Check, Close } from "@material-ui/icons";
import BarLastDaysChartChart from "./chart/BarLastDaysChartChart";
import DonutRepartitionChart from "./chart/DonutRepartitionChart";


const LogPage = () => {
  const dispatch = useDispatch()
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const [waitSync, setWaitSync] = useState<number>(0);
  const [warningReport, setWarningReport] = useState<number>(0);
  const [repartitionCat, setRepartitionCat] = useState<repartitionCategory[]>([]);
  const [lastDays, setLastDays] = useState<lastDays[]>([]);

  function handleNoSync(data: number) {
    if (data) {
      setWaitSync(data)
    }
  }
  function handleWarning(data: number) {
    if (data) {
      setWarningReport(data)
    }
  }
  function handleRepartition(data: repartitionCategory[]) {
    if (data) {
      setRepartitionCat(data)
    }
  }
  function handleRepportByDays(data: lastDays[]) {
    if (data) {
      setLastDays(data)
    }
  }

  function getNoSync() {
    dispatch<any>(getReportsNoSync(currentUser!.token, handleNoSync))
  }

  function getRepartition() {
    dispatch<any>(getRepartitionReport(currentUser!.token, handleRepartition))
  }

  function getAnormal() {
    dispatch<any>(getAnormalReport(currentUser!.token, handleWarning))
  }

  function getLastDays() {
    dispatch<any>(getReportsByDay(currentUser!.token, handleRepportByDays))
  }


  useEffect(() => {
    getNoSync()
    getRepartition()
    getAnormal()
    getLastDays()
  }, []);


  return (
    <Grid container p={2}>
      {/* TITLE */}
      <Grid item mb={2} xs={12}>
        <Typography variant="h4" letterSpacing={5} align="left" my={2} gutterBottom >
          Suivi des rapports
        </Typography>
        <Divider sx={{ backgroundColor: 'blue' }} />
      </Grid>
      {/* BLOC IMAGE */}
      <Grid container alignContent="center" alignItems="center" p={1}>
        {/* banderole warning */}
        <Grid item xs={12}>
          {warningReport === 0 && (
            <Alert icon={<Check fontSize="inherit" />} severity="success">
              Aucun problème détecté dans la synchronisation des rapports
            </Alert>
          )}
          {warningReport > 0 && (
            <Alert icon={<Close fontSize="inherit" />} severity="warning">
              {"Attention " + warningReport + " rapport(s) non pas été synchronisé(s) depuis plus de 24h ! "}
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} mt={5}>
          <Paper >
            <Grid container p={3} alignContent="center" alignItems="center">
              {/* nombre de rapport non sync */}
              <Grid item xs={12} mb={5} sx={{ textAlign: "center" }}>
                <Typography variant="h5" align="center">{`Rapport en attente de synchronisation : ${waitSync}`}</Typography>
              </Grid>
              {/* donut repartition */}
              <Grid item xs={6} alignContent="center" alignItems="center">
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="body1" align="center">{`Répartition des catégories`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <DonutRepartitionChart repartition={repartitionCat} />
                  </Grid>
                </Grid>
              </Grid>
              {/* nombre de rapport 7 derniers jours*/}
              <Grid item xs={6}>
                <Grid container alignContent="center" alignItems="center">
                  <BarLastDaysChartChart lastDays={lastDays} />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LogPage;


