import { fetchApi } from "features/rest";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { lastDays, repartitionCategory } from "./LogType";

  export const getReportsNoSync = (token: string, funcSuccess: (data: number) => void) => async (dispatch: any) => {
    fetchApi({
      method: 'GET',
      endpoint: '/getReportsNoSync',
      token: token,
      data: null,
      funcSuccess: (data) => {
        funcSuccess(data);
      },
      dispatch
    });
  };

  export const getRepartitionReport = (token: string, funcSuccess: (data: repartitionCategory[]) => void) => async (dispatch: any) => {
    fetchApi({
      method: 'GET',
      endpoint: '/getRepartition',
      token: token,
      data: null,
      funcSuccess: (data) => {
        funcSuccess(data);
      },
      dispatch
    });
  };

  export const getAnormalReport = (token: string, funcSuccess: (data: number) => void) => async (dispatch: any) => {
    fetchApi({
      method: 'GET',
      endpoint: '/getAnormalReport',
      token: token,
      data: null,
      funcSuccess: (data) => {
        funcSuccess(data);
      },
      dispatch
    });
  };

  export const getReportsByDay = (token: string, funcSuccess: (data: lastDays[]) => void) => async (dispatch: any) => {
    fetchApi({
      method: 'GET',
      endpoint: '/getReportsByDay',
      token: token,
      data: null,
      funcSuccess: (data) => {
        funcSuccess(data);
      },
      dispatch
    });
  };

